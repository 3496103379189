<template>
  <div class="page-orderList" @click="handleBodyClick">
    <div class="tabs">
      <p
        :class="`tab${tabIdx === i ? '_active' : ''}`"
        v-for="(tab, i) in tabsArr"
        :key="tab"
        @click="handleTabClick(i)"
      >
        {{ tab }}
      </p>
    </div>
    <div class="order-list">
      <div class="filter">
        <p class="filter-value" @click.stop="handleFilterClick">
          <span>{{ filterArr[filterIdx].text }}</span>
          <app-icon
            class="filter-value_icon"
            name="arrow_bottom"
            size="32"
          ></app-icon>
        </p>
        <div :class="`filter-list${filterShow ? '_active' : ''}`">
          <p
            :class="`filter-item${filterIdx === i ? '_active' : ''}`"
            v-for="(item, i) in filterArr"
            :key="i"
            @click="changeFilterIdx(i)"
          >
            {{ item.text }}
          </p>
        </div>
      </div>
      <template v-if="orderList.length">
        <router-link
          :to="!tabIdx ? `/user/orderDetail?orderId=${item.id}` : ''"
          v-for="item in orderList"
          :key="item.id"
          class="order-item"
        >
          <order-item :lotteryData="item"></order-item>
        </router-link>
      </template>
      <div v-else-if="!loading" class="order-empty">
        <p>没有更多{{ filterArr[filterIdx].text }}数据了</p>
      </div>
    </div>
  </div>
</template>

<script>
import orderItem from '@/components/lotteryItem';

export default {
  components: {
    orderItem
  },
  data() {
    return {
      loading: true,
      tabIdx: 0,
      tabsArr: ['普通订单', 'VIP订单'],
      filterIdx: 0,
      filterArr: [
        { text: '未开奖', value: 0 },
        { text: '中奖', value: 1 },
        { text: '未中奖', value: 2 }
      ],
      filterShow: false,
      orderList: []
    };
  },
  methods: {
    getOrderList() {
      this.loading = true;
      let api = this.tabIdx ? 'unlockLog/index' : 'order/index';
      let status = this.filterArr[this.filterIdx].value;
      this.$http(api, { status, page: 1, pageSize: 999 })
        .then(res => {
          this.setOrderList(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setOrderList(data) {
      this.orderList = data.data.map(e => {
        let { forecast } = e;
        let obj = {
          id: e.id,
          forecastId: e.forecastId,
          no: e.no,
          checkNum: forecast.checkNum,
          date: forecast.date,
          rightNumber: forecast.rightNumber,
          forecastType: forecast.type,
          forecastStatus: forecast.status
        };
        if (e.forecast.type === 10) {
          obj.forecastList = [
            forecast.position1,
            forecast.position2,
            forecast.position3,
            forecast.position4,
            forecast.position5
          ];
        } else {
          obj.forecastList = forecast.mapArray;
        }
        return obj;
      });
    },

    handleFilterClick() {
      this.filterShow = !this.filterShow;
    },

    async changeFilterIdx(i = 0) {
      if (this.filterIdx === i) return;
      this.filterIdx = i;
      this.getOrderList();
    },

    handleTabClick(i) {
      this.tabIdx = i;
      this.filterIdx = 0;
      this.getOrderList();
    },

    handleBodyClick() {
      if (this.filterShow) this.filterShow = false;
    }
  },
  activated() {
    this.getOrderList();
  },

  deactivated() {
    if (this.$route.name === 'orderDetail') return false;
    this.filterIdx = 0;
    this.tabIdx = 0;
    this.orderList = [];
  }
};
</script>

<style lang="scss" scoped>
.page-orderList {
  padding: 98px 0 0;
  background: #f5f5f5;
}

.tabs {
  @include align-center;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background: #fff;

  .tab {
    width: 50%;
    text-align: center;
    font-size: 32px;
    font-weight: 500;

    &:first-of-type {
      @include hairlines(right, #dedede);
    }

    &_active {
      @extend .tab;
      color: #ff5225;
    }
  }
}

.filter {
  z-index: 4;
  margin: 0 0 30px;
  width: 240px;
  height: 68px;
  font-size: 30px;

  &-value {
    @include flex-between-center;
    padding: 0 20px 0 40px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    color: #191919;
    background: #f3f3f3;
  }

  &-list {
    @include flex-column;
    position: absolute;
    left: 0;
    bottom: 0px;
    padding: 0 40px;
    width: 100%;
    height: 0px;
    overflow: hidden;
    border-radius: 8px;
    color: #aeaeae;
    background: #fff;
    box-shadow: 0px 1px 26px 0px rgba(153, 153, 153, 0.22);
    transition: all 0.18s;

    &_active {
      @extend .filter-list;
      bottom: -214px;
      height: 206px;
    }
  }

  &-item {
    @include align-center;
    @include hairlines(bottom #e6e6e6);
    flex: 1;
    width: 100%;

    &:last-of-type::after {
      display: none;
    }

    &_active {
      @extend .filter-item;
      color: #191919;
    }
  }
}

.order {
  &-list {
    padding: 30px 30px 60px;
    width: 100%;
    background: #fff;
  }

  &-item {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-empty {
    @include flex-center-center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    font-size: 32px;
    color: #8e8e8e;
  }
}
</style>
