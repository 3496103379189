<template>
  <div class="service-list">
    <service-item
      v-for="item in serviceList"
      :key="item.id"
      class="service-item"
      :data="item"
    >
      <div class="service-pay">
        <div class="service-price">
          <p class="price">
            <span class="price_max">{{ item.money.split('.')[0] }}</span>
            <span>{{
              item.money.split('.')[1] ? `.${item.money.split('.')[1]}` : ''
            }}</span>
          </p>
          <p class="origin">
            <span>原价</span>
            <span class="origin_through">{{ item.lineMoney }}</span>
          </p>
        </div>
        <div
          class="pay-btn"
          :style="{
            background: `url(${item.btnBg}) 0 0 / contain no-repeat`
          }"
          @click="handleServiceBtnClick(item)"
        >
          立即购买
        </div>
      </div>
    </service-item>
    <router-link to="/user/servicePage" class="service-banner"></router-link>
    <pay-popup
      ref="payPopup"
      :show.sync="payPopupShow"
      :content="payData.content"
      :price="payData.price"
      @confirm="handlePayConfirm"
    ></pay-popup>
  </div>
</template>

<script>
import payPopup from '@/components/payPopup';
import serviceItem from '@/components/serviceItem';
import itemBtnBgByYear from 'image/user/service/item-btn_year.png';
import itemBtnBgByMonth from 'image/user/service/item-btn_month.png';
import itemBtnBgByWeek from 'image/user/service/item-btn_week.png';
import itemBtnBgByDay from 'image/user/service/item-btn_day.png';

export default {
  components: {
    serviceItem,
    payPopup
  },
  data() {
    return {
      userId: '',
      serviceList: [],
      payPopupShow: false,
      payData: {
        id: 0,
        content: '',
        price: ''
      },
      itemBtnBgByYear,
      itemBtnBgByMonth,
      itemBtnBgByWeek,
      itemBtnBgByDay
    };
  },
  methods: {
    getServiceList() {
      this.$http('service/index', { page: 1, pageSize: 999 }).then(res => {
        this.serviceList = res.map(e => {
          e.money = `${Number(e.money)}`;
          e.lineMoney = `${Number(e.lineMoney)}`;
          e.type = e.typeKey;
          e.serviceName = e.name;
          e.btnBg =
            this[
              `itemBtnBgBy${e.typeKey.replace(/^\S/, s => s.toUpperCase())}`
            ];
          return e;
        });
      });
    },

    handleServiceBtnClick(item) {
      this.payData.id = item.id;
      this.payData.price = item.money;
      this.payData.content = `生彩有道-${item.name}会员`;
      this.payPopupShow = true;
    },

    async handlePayConfirm(payType) {
      try {
        let data = { serviceId: this.payData.id, payType };
        let res = await this.$http(
          'orderService/add',
          this.$util.transformKey(data)
        );
        if (payType === 2) {
          this.$refs.payPopup.updateUserMoney(res);
          return this.handlePaySuccess();
        }
        await this.$wx
          .pay(res)
          .then(money => {
            this.handlePaySuccess(money);
          })
          .catch(() => this.handlePayFail());
      } catch (error) {
        this.handlePayFail(JSON.parse(error.message));
      }
    },

    handlePaySuccess() {
      this.$dialog
        .confirm({
          title: '购买成功',
          message: '恭喜您成为我们的会员，马上回首页浏览大师预测吧！',
          cancelButtonText: '再看看',
          confirmButtonText: '回首页'
        })
        .then(() => {
          this.$router.replace('/');
        })
        .catch(() => {
          this.payPopupShow = false;
        });
    },

    handlePayFail(err) {
      let config = {
        title: (err && '购买失败') || '',
        message: (err && err.message) || '购买失败'
      };
      this.$dialog.alert(config);
    }
  },
  created() {
    this.getServiceList();
  }
};
</script>

<style lang="scss" scoped>
.service {
  &-list {
    padding: 293px 30px 148px;
    min-height: 100vh;
    background: #b71919 url(~image/user/service/service-bg.png) 0 0 / contain
      no-repeat;
  }

  &-item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-pay {
    @include justify-center;
    flex-direction: column;
    position: absolute;
    top: 37px;
    right: 40px;

    .pay {
      &-btn {
        @include flex-center-center;
        z-index: 1;
        width: 195px;
        height: 68px;
        font-size: 34px;
        font-weight: 500;
        color: #414141;
      }
    }
  }

  &-price {
    @include flex-center-center;

    .price {
      margin-right: 20px;
      text-align: center;
      font-size: 34px;
      font-weight: 600;
      color: #000;

      &_max {
        font-size: 68px;
      }
    }

    .origin {
      display: flex;
      flex-direction: column;
      font-size: 22px;
      color: #e70000;

      &_through {
        text-decoration: line-through;
      }
    }
  }

  &-banner {
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 108px;
    background: url(~image/user/service/banner_superVip.png) 0 0 / cover
      no-repeat;
  }
}
</style>
