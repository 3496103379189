<template>
  <div class="page-serviceOrder" v-if="orderData">
    <order-detail class="order-container" :data="orderData">
      <template v-slot:tip>
        <p class="service-limit">
          有效期：{{ orderData.beginTime | dateFormat('YYYY/MM/DD HH:mm') }} -
          {{ orderData.endTime | dateFormat('YYYY/MM/DD HH:mm') }}
        </p>
      </template>
    </order-detail>
    <div class="order-info">
      <p class="info-item">
        <span class="info-label">交易单号：</span>
        <span>{{ orderData.orderNo }}</span>
      </p>
      <p class="info-item">
        <span class="info-label">交易时间：</span>
        <span>{{ orderData.payTime | dateFormat('YYYY-MM-DD HH:mm') }}</span>
      </p>
      <p class="info-item">
        <span class="info-label">购买内容：</span>
        <span>生彩有道-{{ orderData.serviceName }}会员</span>
      </p>
      <p class="info-item">
        <span class="info-label">支付金额：</span>
        <span>¥ {{ Number(orderData.price) || 0 }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import orderDetail from '@/components/serviceItem';

export default {
  components: {
    orderDetail
  },
  data() {
    return {
      orderId: '',
      orderData: null
    };
  },
  methods: {
    getOrderData() {
      this.$http('orderService/info', { id: this.orderId }).then(res => {
        res.payTime *= 1000;
        res.type = res.serviceType;
        this.orderData = res;
      });
    }
  },
  async created() {
    this.orderId = this.$route.query.orderId;
    if (!this.orderId) return;
    this.getOrderData();
  }
};
</script>

<style lang="scss" scoped>
.page-serviceOrder {
  padding: 30px;
}
.order {
  &-container {
    .service-limit {
      margin-bottom: 50px;
      font-size: 24px;
    }
  }
  &-info {
    margin: 30px auto 0;
    padding: 40px 30px;
    width: 100%;
    border-radius: 16px;
    font-size: 28px;
    color: #4d4d4d;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.24);

    .info {
      &-item {
        @include flex-between-center;
        margin-bottom: 30px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &_label {
        color: #999;
      }
    }
  }
}
</style>
