<template>
  <div class="module-serviceItem" v-if="serviceData">
    <img class="service-bg" :src="serviceData.bgImg" />
    <slot></slot>
    <div class="service-title">
      <p>{{ serviceData.name }}</p>
      <div :class="`service-icon_${serviceData.type}`"></div>
    </div>
    <slot name="tip">
      <p class="service-tip">
        {{ serviceData.tip }}
      </p>
    </slot>
    <p class="service-text">{{ serviceData.text }}</p>
  </div>
</template>

<script>
import itemBgByYear from 'image/user/service/item-bg_year.png';
import itemBgByMonth from 'image/user/service/item-bg_month.png';
import itemBgByWeek from 'image/user/service/item-bg_week.png';
import itemBgByDay from 'image/user/service/item-bg_day.png';
import itemBgByVip from 'image/user/service/item-bg_vip.png';

export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(data) {
        this.setServiceData(data);
      }
    }
  },
  data() {
    return {
      serviceData: null,
      serviceTypeData: {
        day: {
          tip: '为期24小时',
          text: '以开卡日期起算的24小时内，可免费查阅本平台大师榜预测码。',
          bgImg: itemBgByDay
        },
        week: {
          tip: '为期7天',
          text: '以开卡日期起算的7天内，可免费查阅本平台大师榜预测码。',
          bgImg: itemBgByWeek
        },
        month: {
          tip: '为期一个自然月',
          text: '以开卡日期起算的一个自然月内，可免费查阅本平台大师榜预测码。',
          bgImg: itemBgByMonth
        },
        year: {
          tip: '为期一个自然年',
          text: '以开卡日期起算的一个自然年内，可免费查阅本平台大师榜预测码。',
          bgImg: itemBgByYear
        },
        vip: {
          tip: '为期一个自然月',
          text: '以开卡日期起算的一个自然月内，可享受平台AI的智能码师推荐，及查阅平台内所有码师的回报分析数据。',
          bgImg: itemBgByVip
        }
      }
    };
  },
  methods: {
    setServiceData(data) {
      let typeData = this.serviceTypeData[data.type];
      this.serviceData = {
        id: data.id,
        type: data.type,
        name: data.serviceName,
        price: Number(data.money) || 0,
        tip: typeData.tip,
        text: typeData.text,
        bgImg: typeData.bgImg
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.module-serviceItem {
  z-index: 1;
  padding: 60px 40px 0;
  width: 100%;
  height: 326px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.28);
}

.service {
  &-bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
  }

  &-title {
    @include align-center;
    margin: 0 0 8px 0;
    font-size: 52px;
    font-weight: 600;
    color: #000;
  }

  &-icon {
    margin-left: 11px;

    &_day,
    &_month,
    &_yaer {
      @extend .service-icon;
      width: 66px;
      height: 34px;
      background: url(~image/user/service/vip.png) 0 0 / cover no-repeat;
    }

    &_vip {
      @extend .service-icon;
      width: 58px;
      height: 40px;
      background: url(~image/user/service/superVip.png) 0 0 / cover no-repeat;
    }
  }

  &-tip {
    margin-bottom: 49px;
    font-size: 28px;
    font-weight: 500;
    color: #323232;
  }

  &-text {
    line-height: 36px;
    color: #474747;
  }
}
</style>
