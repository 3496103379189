<template>
  <van-popup class="module-success" :value="show">
    <div class="success-wrap">
      <img
        class="success-img"
        src="~image/user/register/success-icon.png"
        alt=""
      />
      <p>
        提交申请成功，您可以在个人中心随时查看审核状态，请耐心等候审核，感谢您的加入。
      </p>
      <div class="success-btn" @click="handleConfirmClick">好的</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    handleConfirmClick() {
      this.$emit('update:show', false);
      this.$router.replace('/user');
    }
  }
};
</script>

<style lang="scss" scoped>
.module-success {
  overflow: initial;
}

.success {
  &-wrap {
    padding: 105px 33px 41px;
    width: 542px;
    line-height: 54px;
    border-radius: 16px;
    text-align: center;
    font-size: 36px;
    color: #232323;
    background: #fff;
  }

  &-img {
    z-index: 2;
    position: absolute;
    top: -203px;
    left: calc(50% - 112px);
    width: 225px;
    height: 248px;
  }

  &-btn {
    @include flex-center-center;
    margin: 64px auto 0;
    width: 326px;
    height: 98px;
    border-radius: 49px;
    font-size: 34px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(180deg, #fb9d43 0%, #ff3f21 100%);
    box-shadow: 0px 5px 18px 0px rgba(255, 40, 0, 0.28);
  }
}
</style>
